export default {
  basicViewItem: [{
    prop: 'id',
    label: '',
    Object: 'value'
  }, {
    prop: 'approval_status',
    label: '',
    Object: 'value'
  }, {
    prop: 'name',
    label: '',
    Object: 'value'
  }, {
    prop: 'phone_number',
    label: '',
    Object: 'value'
  }, {
    prop: 'whatsapp_number',
    label: '',
    Object: 'value'
  }, {
    prop: 'wechat_id',
    label: '',
    Object: 'value'
  }, {
    prop: 'email',
    label: '',
    Object: 'value'
  }, {
    prop: 'had_past_experience',
    label: '',
    Object: 'value'
  }, {
    prop: 'support_temp_sensitive_delivery',
    label: '',
    Object: 'value'
  }, {
    prop: 'support_bulk_delivery',
    label: '',
    Object: 'value'
  }, {
    prop: 'ic_front_image',
    label: '',
    image: true,
    span: 24
  }, {
    prop: 'ic_back_image',
    label: '',
    image: true,
    span: 24
  }, {
    prop: 'full_address',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'address_photo',
    label: '',
    image: true,
    span: 24
  }, {
    prop: 'source',
    label: '',
    Object: 'value',
    span: 24
  }]
}
