import request from '@/utils/request'

export const getSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/host_applications/setup'
  })
}

export const getHostApplications = (params) => {
  return request({
    method: 'GET',
    url: '/cms/host_applications',
    params
  })
}

export const getHostApplicationsId = (id) => {
  return request({
    method: 'GET',
    url: `/cms/host_applications/${id}`
  })
}
