// 组件
import createTable from '@/components/createTable'
import viewFrame from '@/components/viewFrame'
import customeTable from '@/components/customeTable'
import dialogEffect from '@/components/dialogEffect'
// 接口
import * as hostApplicationsApi from '@/services/host-applications'
import { getHistoryList } from '@/services/resource'
// 其他
import loading from '@/utils/loading.json'
import history from '@/common/data/jsonData'
import jsonData from './jsonData'

export default {
  components: {
    createTable,
    viewFrame,
    customeTable,
    dialogEffect
  },
  data () {
    return {
      validateVisible: false,
      buttonObj: [],
      titleObject: {},
      custName: {},
      formData: {},
      modifyData: {},
      type: '',
      basicViewItem: jsonData.basicViewItem,
      historyTableItem: history.historyTableItem,
      dialogData: [],
      batchData: [],
      dialogVisible: false,
      historyTableData: []
    }
  },
  created () {
    const _this = this
    _this.urlParams = _this.$route.params
  },
  mounted () {
    const _this = this
    _this.init()
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = _this.$loading(loading) : _this.$store.state.app.loading.close()
    },
    // 初始化
    async init () {
      const _this = this
      _this.setGlobalLoading(true)
	  const res = await hostApplicationsApi.getHostApplicationsId(_this.urlParams.id)
	  if (res.status === 200) {
		  _this.custName = res.data.host
		  Object.assign(this.titleObject, { title: '', data: '' })
	  }
	  _this.setGlobalLoading(false)
      //  获取历史操作
      getHistoryList({ item_type: 'Host', item_id: _this.urlParams.id }).then(res => {
        this.historyTableData = res.data.versions
      })
    }
  }
}
